<template>
  <div>
    <base-table
      ref="tableRef"
      :columnsData="columns"
      rowKey="id"
      :tableData="tableData"
      :total="total"
      :page.sync="tableForm.page"
      :pageSize.sync="tableForm.page_count"
    >
    
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">临时运费策略</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
            <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          </a-tabs>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')" html-type="submit">新增</a-button>
        </div>
      </template>

      <template #platform="{record}">
        <span v-if="record.platform_xcx==2" class="ml-1 mr-1">小程序</span>
        <span v-if="record.platform_pc==2" class="ml-1 mr-1">网站</span>
      </template>
      <template #status="{text}">
        <a-tag class="mr-0" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="updateStatus(record)">{{record.status == 1? '停用' : '恢复'}}</a-button>
      </template>
    </base-table>
    <a-modal
      v-model="isEidtShow"
      width="400px"
      :title="type=='add'?'新增临时运费策略': '编辑临时运费策略'"
      @ok="handlerSave"
    >
      <a-form-model
        ref="tempRef"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 20 }"
        :model="addForm"
      >
        <a-form-model-item class="mb-2" prop="name" label="备注名">
          <a-input allowClear v-model="addForm.name" placeholder="请输入活动备注名"></a-input>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="name" label="活动平台">
          <a-radio-group name="platform_type" v-model="platform_type">
            <a-radio value="1">小程序</a-radio>
            <a-radio value="2">网站</a-radio>
          </a-radio-group>
          <!-- <a-input allowClear v-model="addForm.name" placeholder="请输入活动名称"></a-input> -->
        </a-form-model-item>

        <a-form-model-item class="mb-2" prop="freight" label="包邮门槛">
          <a-input-number
            :step="0.01"
            allowClear
            style="width: 90%"
            v-model="addForm.free_delivery_threshold"
            placeholder="请输入包邮门槛"
          ></a-input-number>
          <span style="margin-left:5px;">元</span>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="timeArr" label="活动时间">
          <a-range-picker v-model="addForm.timeArr" valueFormat="YYYY-MM-DD" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getTempList, addTempFreight, updateTempFreight } from "@/api/activity/freight"
export default {
  data() {
    return {
      total: 0,
      activeKey: 1,
      typeList: [
        { type_id: 1, type_name: "未开始" },
        { type_id: 2, type_name: "进行中" },
        { type_id: 3, type_name: "已结束" },
      ],
      requestID: "",
      columns: [
        {
          title: "活动备注名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "适用平台",
          dataIndex: "platform",
          width: 180,
          slots: { customRender: "platform" },
          align: "center",
        },
        {
          title: "包邮门槛",
          dataIndex: "free_delivery_threshold",
          align: "center",
          width: 90,
        },
        {
          title: "活动开始",
          dataIndex: "start_time",
          align: "center",
        },
        {
          title: "活动结束",
          dataIndex: "end_time",
          align: "center",
        },
        {
          title: "活动状态",
          dataIndex: "status",
          align: "center",
          width: 100,
          slots: {
            customRender: "status",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: 120,
          slots: { customRender: "operation" },
          align: "center",
        },
      ],
      platform_type: "1",
      addForm: {
        freight_type_id: "",
        name: "",
        platform_xcx: 2,
        platform_pc: 1,
        free_delivery_threshold: "",
        timeArr: [],
        start_time: "",
        end_time: "",
      },
      tableForm: {
        freight_type_id: "",
        date_type: 1,
        timeArr: [],
        start_time: "",
        end_time: "",
        page: 1,
        page_count: 20,
      },
      type: "add",
      tableData: [],
      isEidtShow: false,
    }
  },
  mounted() {
    this.requestID = this.$route.query.freightID
    this.initData()
  },
  methods: {
    async initData() {
      this.tableForm.freight_type_id = this.requestID
      const { data, code } = await getTempList(this.tableForm)
      if (code === 0) {
        this.tableData = data.list
      }
    },
    updateStatus(row) {
      updateTempFreight(row).then((res) => {
        if (res.code === 0) {
          row.status = row.status == 1 ? 0 : 1
          this.$message.success("修改成功")
          this.initData()
        }
      })
    },
    handlerSave() {
      if(this.platform_type == 1){
        this.addForm.platform_xcx = 2
        this.addForm.platform_pc  = 1
      }else{
        this.addForm.platform_xcx = 1
        this.addForm.platform_pc  = 2
      }
      if (this.type === "add") {
        const par = this.addForm
        if (this.addForm.timeArr.length > 0) {
          par.start_time = this.addForm.timeArr[0]
          par.end_time = this.addForm.timeArr[1]
        }
        this.addForm.freight_type_id = this.requestID
        addTempFreight(par).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增运费模板成功！")
            this.isEidtShow = false
            this.initData()
          }
        })
      } else {
        const par = this.addForm
        if (this.addForm.timeArr.length > 0) {
          par.start_time = this.addForm.timeArr[0]
          par.end_time = this.addForm.timeArr[1]
        }
        updateTempFreight(par).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改运费模板成功！")
            this.isEidtShow = false
            this.initData()
          }
        })
      }
    },
    handlerEdit(type, row) {
      this.type = type
      if (row) {
        this.addForm = JSON.parse(JSON.stringify(row))
        this.addForm.timeArr = [row.start_time, row.end_time]
        this.platform_type = this.addForm.platform_xcx==2? "1" : "2"
      } else {
        this.addForm.name = ""
        this.addForm.freight = ""
        this.addForm.free_delivery_threshold = ""
        this.platform_type = "1"
      }
      this.isEidtShow = true
    },
    handlerTypeChange(e) {
      this.tableForm.page = 1
      this.tableForm.date_type = e
      this.activeKey = e
      this.initData()
    },
  },
}
</script>

<style>
</style>